import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { htmlSerializer } from '../utils/htmlSerializer';

const HeroContent = ({
  heroTitle,
  heroDescription,
  rightAlign,
  isTitleOnBottom,
}) => {
  let alignmentClassName = 'items-start';
  if (rightAlign) {
    alignmentClassName = 'lg:items-end';
  }

  let content = null;
  if (isTitleOnBottom) {
    content = (
      <>
        <div className='max-w-xl justify-end pb-4 text-left text-sm leading-relaxed text-gray-200 md:text-base lg:text-justify xl:max-w-2xl mega:max-w-3xl'>
          <PrismicRichText
            field={heroDescription}
            components={htmlSerializer}
          />
        </div>
        <p className='mb-4 max-w-xl text-left text-lg font-semibold uppercase leading-relaxed text-gray-200 lg:max-w-3xl lg:text-3xl'>
          {heroTitle}
        </p>
      </>
    );
  } else {
    content = (
      <>
        <p className='mb-4 max-w-xl text-left text-lg font-semibold uppercase text-gray-200 lg:max-w-3xl lg:text-3xl'>
          {heroTitle}
        </p>
        <div className='max-w-xl justify-end pb-8 text-left text-sm leading-relaxed text-gray-200 md:text-base lg:text-justify xl:max-w-2xl mega:max-w-3xl'>
          <PrismicRichText
            field={heroDescription}
            components={htmlSerializer}
          />
        </div>
      </>
    );
  }
  return (
    <div
      className={`flex flex-col tiny:mt-3 mobile:mt-8  ${alignmentClassName}`}
    >
      {content}
    </div>
  );
};

export default HeroContent;
